const Feature_collections_data = [
  {
    id: "0Art Me Outside",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Wow Frens",
    itemsCount: "10K",
    title: "Art Me Outside",
    category: "art",
    category: "Collectibles",
    category: "photography",
    top: true,
    trending: true,
    recent: true,
  },
  {
    id: "1PankySkal",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "NFT stars",
    itemsCount: "2.8K",
    title: "PankySkal",
    category: "Collectibles",
    category: "photography",
    category: "music",
    top: false,
    trending: false,
    recent: false,
  },
  {
    id: "2VR Space_287",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Origin Morish",
    itemsCount: "8K",
    title: "VR Space_287",
    category: "domain",
    category: "photography",
    top: true,
    trending: false,
    recent: true,
  },
  {
    id: "3Metasmorf",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Lazy Panda",
    itemsCount: "1.5K",
    title: "Metasmorf",
    category: "music",
    category: "photography",
    category: "art",
    top: false,
    trending: true,
    recent: false,
  },
  {
    id: "43Landers",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "051_Hart",
    itemsCount: "15K",
    title: "3Landers",
    category: "photography",
    category: "Collectibles",
    category: "music",
    top: true,
    trending: false,
    recent: true,
  },
  {
    id: "5SlimHoods",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Crytopank",
    itemsCount: "8.8K",
    title: "SlimHoods",
    category: "virtual world",
    category: "music",
    category: "art",
    top: true,
    trending: true,
    recent: false,
  },
  {
    id: "6The Overseers",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Hey Mrsmeseks",
    itemsCount: "13K",
    title: "The Overseers",
    category: "art",
    category: "Collectibles",
    top: false,
    trending: true,
    recent: true,
  },

  {
    id: "7Dope Shibas",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "alyxbow",
    itemsCount: "3K",
    title: "Dope Shibas",
    category: "Collectibles",
    category: "art",
    top: true,
    trending: false,
    recent: true,
  },
  {
    id: "8Art Me Outside",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Wow Frens",
    itemsCount: "10K",
    title: "Art Me Outside",
    category: "art",
    top: true,
    trending: true,
    recent: false,
  },
  {
    id: "9PankySkal",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "NFT stars",
    itemsCount: "2.8K",
    title: "PankySkal",
    category: "Collectibles",
    top: false,
    trending: true,
    recent: true,
  },
  {
    id: "10VR Space_287",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Origin Morish",
    itemsCount: "8K",
    title: "VR Space_287",
    category: "domain",
    category: "Collectibles",
    top: true,
    trending: false,
    recent: true,
  },
  {
    id: "11Metasmorf",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Lazy Panda",
    itemsCount: "1.5K",
    title: "Metasmorf",
    category: "music",
    category: "Collectibles",
    category: "virtual world",
    top: true,
    trending: true,
    recent: false,
  },
  {
    id: "123Landers",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "051_Hart",
    itemsCount: "15K",
    title: "3Landers",
    category: "photography",
    category: "domain",
    category: "virtual world",
    top: false,
    trending: true,
    recent: true,
  },
  {
    id: "13SlimHoods",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Crytopank",
    itemsCount: "8.8K",
    title: "SlimHoods",
    category: "virtual world",
    category: "domain",
    top: true,
    trending: false,
    recent: true,
  },
  {
    id: "14The Overseers",
    bigImage: "/images/nft/art.jpeg",
    subImage1: "/images/nft/art.jpeg",
    subImage2: "/images/nft/art.jpeg",
    subImage3: "/images/nft/art.jpeg",
    userImage: "/images/avatars/user.jpeg",
    userName: "Hey Mrsmeseks",
    itemsCount: "13K",
    title: "The Overseers",
    category: "domain",
    category: "virtual world",
    top: true,
    trending: true,
    recent: false,
  },

  // {
  // 	id: '151Dope Shibas',
  // 	bigImage: '/images/collections/collection_8_1.jpg',
  // 	subImage1: '/images/collections/collection_8_2.jpg',
  // 	subImage2: '/images/collections/collection_8_3.jpg',
  // 	subImage3: '/images/collections/collection_8_4.jpg',
  // 	userImage: '/images/avatars/owner_12.png',
  // 	userName: 'alyxbow',
  // 	itemsCount: '3K',
  // 	title: 'Dope Shibas',
  // 	category: 'Collectibles',
  // 	top: false,
  // 	trending: true,
  // 	recent: true,
  // },
  // {
  // 	id: '16Art Me Outside',
  // 	bigImage: '/images/collections/collection_1_1.jpg',
  // 	subImage1: '/images/collections/collection_1_2.jpg',
  // 	subImage2: '/images/collections/collection_1_3.jpg',
  // 	subImage3: '/images/collections/collection_1_4.jpg',
  // 	userImage: '/images/avatars/owner_5.png',
  // 	userName: 'Wow Frens',
  // 	itemsCount: '10K',
  // 	title: 'Art Me Outside',
  // 	category: 'art',
  // 	top: true,
  // 	trending: false,
  // 	recent: true,
  // },
  // {
  // 	id: '17PankySkal',
  // 	bigImage: '/images/collections/collection_2_1.jpg',
  // 	subImage1: '/images/collections/collection_2_2.jpg',
  // 	subImage2: '/images/collections/collection_2_3.jpg',
  // 	subImage3: '/images/collections/collection_2_4.jpg',
  // 	userImage: '/images/avatars/owner_9.png',
  // 	userName: 'NFT stars',
  // 	itemsCount: '2.8K',
  // 	title: 'PankySkal',
  // 	category: 'Collectibles',
  // 	top: true,
  // 	trending: true,
  // 	recent: false,
  // },
  // {
  // 	id: '18VR Space_287',
  // 	bigImage: '/images/collections/collection_3_1.jpg',
  // 	subImage1: '/images/collections/collection_3_2.jpg',
  // 	subImage2: '/images/collections/collection_3_3.jpg',
  // 	subImage3: '/images/collections/collection_3_4.jpg',
  // 	userImage: '/images/avatars/owner_4.png',
  // 	userName: 'Origin Morish',
  // 	itemsCount: '8K',
  // 	title: 'VR Space_287',
  // 	category: 'domain',
  // 	top: false,
  // 	trending: true,
  // 	recent: true,
  // },
  // {
  // 	id: '19Metasmorf',
  // 	bigImage: '/images/collections/collection_4_1.jpg',
  // 	subImage1: '/images/collections/collection_4_2.jpg',
  // 	subImage2: '/images/collections/collection_4_3.jpg',
  // 	subImage3: '/images/collections/collection_4_4.jpg',
  // 	userImage: '/images/avatars/owner_10.png',
  // 	userName: 'Lazy Panda',
  // 	itemsCount: '1.5K',
  // 	title: 'Metasmorf',
  // 	category: 'music',
  // 	top: true,
  // 	trending: false,
  // 	recent: true,
  // },
  // {
  // 	id: '203Landers',
  // 	bigImage: '/images/collections/collection_5_1.jpg',
  // 	subImage1: '/images/collections/collection_5_2.jpg',
  // 	subImage2: '/images/collections/collection_5_3.jpg',
  // 	subImage3: '/images/collections/collection_5_4.jpg',
  // 	userImage: '/images/avatars/owner_11.png',
  // 	userName: '051_Hart',
  // 	itemsCount: '15K',
  // 	title: '3Landers',
  // 	category: 'photography',
  // 	top: true,
  // 	trending: true,
  // 	recent: false,
  // },
  // {
  // 	id: '21SlimHoods',
  // 	bigImage: '/images/collections/collection_6_1.jpg',
  // 	subImage1: '/images/collections/collection_6_2.jpg',
  // 	subImage2: '/images/collections/collection_6_3.jpg',
  // 	subImage3: '/images/collections/collection_6_4.jpg',
  // 	userImage: '/images/avatars/owner_3.png',
  // 	userName: 'Crytopank',
  // 	itemsCount: '8.8K',
  // 	title: 'SlimHoods',
  // 	category: 'virtual world',
  // 	top: false,
  // 	trending: true,
  // 	recent: true,
  // },
  // {
  // 	id: '22The Overseers',
  // 	bigImage: '/images/collections/collection_7_1.jpg',
  // 	subImage1: '/images/collections/collection_7_2.jpg',
  // 	subImage2: '/images/collections/collection_7_3.jpg',
  // 	subImage3: '/images/collections/collection_7_4.jpg',
  // 	userImage: '/images/avatars/creator_2.png',
  // 	userName: 'Hey Mrsmeseks',
  // 	itemsCount: '13K',
  // 	title: 'The Overseers',
  // 	category: 'art',
  // 	top: true,
  // 	trending: false,
  // 	recent: true,
  // },

  // {
  // 	id: '23Dope Shibas',
  // 	bigImage: '/images/collections/collection_8_1.jpg',
  // 	subImage1: '/images/collections/collection_8_2.jpg',
  // 	subImage2: '/images/collections/collection_8_3.jpg',
  // 	subImage3: '/images/collections/collection_8_4.jpg',
  // 	userImage: '/images/avatars/owner_12.png',
  // 	userName: 'alyxbow',
  // 	itemsCount: '3K',
  // 	title: 'Dope Shibas',
  // 	category: 'Collectibles',
  // 	top: true,
  // 	trending: true,
  // 	recent: false,
  // },
];

export default Feature_collections_data;
